
import { defineComponent } from "vue";
import { Field, Form } from "vee-validate";
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";

import { ElMessage } from "element-plus/lib/components/message";
import { ICreateCustomerForm } from "@/models/Customer";
import CustomerModule from "@/store/modules/Customer";

export default defineComponent({
  components: {
    Form,
    Field,
    BaseSvgIcon,
  },
  data() {
    return {
      imageUrl: "",
      fileUrl: "",
    };
  },
  computed: {},
  methods: {
    async onSubmit(values: any, actions: any) {
      await CustomerModule.createCustomer(values as ICreateCustomerForm);
      if (!CustomerModule.isError) {
        //actions.resetForm();
      }
    },
    handleUploadImageSuccess(res: any, file: any, field: any) {
      console.log(URL.createObjectURL(file.raw));
    },
    beforeUploadImage(file: any) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        ElMessage.error("Avatar picture must be JPG format!");
      }
      if (!isLt2M) {
        ElMessage.error("Avatar picture size can not exceed 2MB!");
      }
      return isJPG && isLt2M;
    },
    handleUploadFileSuccess(res: any, file: any, field: any) {
      console.log(URL.createObjectURL(file.raw));
    },
    beforeUploadFile(file: any) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        ElMessage.error("Avatar picture must be JPG format!");
      }
      if (!isLt2M) {
        ElMessage.error("Avatar picture size can not exceed 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
});
